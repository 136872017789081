import sanitizeHtml from 'sanitize-html';

export const toText = (html: string): string => {
  return sanitizeHtml(html, { allowedTags: [] });
};

/**
 * 1つ目の画像を取得する
 * @param html 本文
 * @param attribute imgからurlを取得する属性
 * @return 画像URL もしくは undefined
 */
export const getFirstImage = (html: string, attribute: 'src' | 'srcset' = 'src'): string | undefined => {
  const regex = new RegExp(`.+?(${attribute}=("|')(?<imagePath>.*?)("|').*?>?)`);
  const imagePath = html.match(regex)?.groups?.imagePath;
  if (imagePath) {
    const match = imagePath.match(/(https?:\/\/[^\s]+)/);
    if (match) return match[0];
  }
  return undefined;
};

export const sanitizeHtmlForContent = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat('img', 'h1', 'strong', 'p', 'span', 'a', 'ul', 'ol', 'li', 'figure', 'div', 'iframe', 'table', 'tbody', 'tr', 'th', 'td', 'blockquote', 'oembed', 'br', 'i'),
    allowedAttributes: {
      '*': ['style', 'class', 'url'],
      'img': [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ],
      'a': [ 'href', 'name', 'target' ]
    },
  });
};
