import { getFirstImage } from './html';
import noImage from '@/assets/images/no-image-square.png';
import { ApplicationConfig } from '@/config/applicationConfig';

/**
 * アイキャッチ画像、本文の一枚目の画像、デフォルト画像の優先度でサムネを取得する
 * @param eyeCatchingImage アイキャッチ画像
 * @param body 本文
 * @param attribute imgからurlを取得する属性
 * @param default_image デフォルト画像
 * @returns 画像url
 */
export const getThumbnailUrl = (eyeCatchingImage: string | undefined | null, body: string, attribute: 'src' | 'srcset' = 'src', default_image: 'group_logo' | 'house' = 'group_logo') => {
  if (eyeCatchingImage) {
    return eyeCatchingImage;
  } else if (getFirstImage(body, attribute)) {
    return getFirstImage(body, attribute);
  } else if (default_image === 'house') {
    return `${ApplicationConfig.customerUrl}/images/information_thumbnail.png`; // NOTE: public以下の画像呼び出し
  } else {
    return noImage.src;
  }
};
