import {
  Box,
  Text
} from '@chakra-ui/react';
import { FaTag } from 'react-icons/fa';

export type InformationsCategoryTagProps = {
  tagName: string;
}

export const InformationTag = ({ tagName }: InformationsCategoryTagProps) => {
  return (
    <Box
      textAlign={'center'}
      display={'inline'}
      alignItems={'center'}
      color={'brand.500'}
      mr={4}
    >
      <FaTag
        style={{
          fontSize: '10px',
          display: 'inline'
        }}
      />
      <Text
        fontWeight={'semibold'}
        fontSize={'12px'}
        display={'inline'}
        pl={1}
      >
        {tagName}
      </Text>
    </Box>
  );
};
