import {
  Box,
  BoxProps
} from '@chakra-ui/react';
import { Image } from '@/components/Image';

interface HeadingImageProps extends BoxProps {
  headingImage: string;
  headingAlt: string;
  width: number | string;
  height: number | string;
  as: 'h1' | 'h2' | 'h3' | 'h4'
}

export const HeadingImage = ({
  headingImage,
  headingAlt,
  width,
  height,
  as,
  ...props
}: HeadingImageProps) => {
  return (
    <Box
      display={'inline'}
      as={as}
      {...props}
    >
      <Image
        src={headingImage}
        alt={headingAlt}
        width={width}
        height={height}
      />
    </Box>
  );
};
